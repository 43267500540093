<template>
  <div>
    <Page :tabList="tabList"></Page>
  </div>
</template>

<script>
import Page from "../../components/public/Page.vue";
export default {
  components: {
    Page,
  },
  data() {
    return {
      tabList: [
        // { name: "题库维护", path: "/maintain" },
        // // { name: "临时题库(安全)", path: "/interim" },
        // { name: "内外审问题点题库", path: "/scrutiny" },
        // { name: "题库导入", path: "/import" },
        { name: "岗位职能表", path: "/post-fun-table" },
        // { name: "考试题库", path: "/exam-bank" },
        // { name: "专题题库", path: "/theme-import" },
        // { name: "标准题库", path: "/standard-import" },
        // { name: "成熟度自评题库", path: "/maturity" },
        { name: "各个标准通用题库", path: "/standard-import" },
        { name: "基于文件抽取题库", path: "/maintain" },
        { name: "专题题库", path: "/theme-import" },
        { name: "内外审问题点", path: "/scrutiny" },
        { name: "考试题库", path: "/exam-bank" },
        { name: "成熟度自评题库", path: "/maturity" },
        { name: "日常检查题库", path: "/import7s" },

      ],
    };
  },
};
</script>

<style>
</style>